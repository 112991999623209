import React, { useState } from 'react';

import { IHeaderContainerProps } from '../HeaderContainer.types';
import { useScrollPosition } from '../../../providers/useScrollPosition';

const getTriggerPositionByAnimationName = (animation: string) => {
  switch (animation) {
    case 'HeaderFadeOut':
      return 200;
    case 'HeaderHideToTop':
      return 400;
    default:
      return null;
  }
};

const HeaderContainer: React.FC<IHeaderContainerProps> = props => {
  const { id, skin: HeaderContainerClass, children, animations } = props;

  const [animationProp, setAnimationProp] = useState<string>('');

  useScrollPosition(
    ({ currPos }) => {
      animations!.forEach(animationObject => {
        // until TB will fix the type
        // @ts-ignore
        animationObject.params.animations.forEach(animation => {
          const triggerPosition = getTriggerPositionByAnimationName(
            animation.name,
          );
          if (triggerPosition) {
            if (animation.name === animationProp) {
              if (currPos.y * -1 < triggerPosition) {
                setAnimationProp(animation.name + 'Reverse');
              }
            } else if (currPos.y * -1 >= triggerPosition) {
              setAnimationProp(animation.name);
            }
          }
        });
      });
    },
    [animationProp],
    { disabled: !animations || !animations.length },
  );

  return (
    <HeaderContainerClass
      wrapperProps={{ id, tagName: 'header' }}
      animation={animationProp}
    >
      {children()}
    </HeaderContainerClass>
  );
};

export default HeaderContainer;
